import React from 'react';
import { Layout, Col, Card } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import bestPractices from '../assets/bestPractices.png';
import automationTags from '../assets/automationtags.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function BestPractices() {
  return (
    <Content className={styles.basicInfo}>
      <Row style={{ margin: '50px auto' }}>
        <h1 style={{ width: '100%', textAlign: 'center' }}>
          <span>Tagging best practices.</span>
          <br />
          Identify tagging requirements.
        </h1>
        <div className={styles.frDiv}>
          <p className={styles.basicInfoTxtc}>
            It is extremely important to align all of the stakeholders,
            including technical and business units, to identify the exact
            tagging requirements of each group in order to deploy an effective
            tagging strategy that works for the entire organization.{' '}
          </p>
        </div>
      </Row>

      <Row
        gutter={32}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      >
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card bordered={false} className={styles.reinsuranceCardSeven}>
            <h2>Less is more</h2>
            <p>
              The simpler the approach to tagging, the easier it is to implement
              and maintain. Reducing redundant tags will simplify and streamline
              the implementation and maintenance of the tagging process. Start
              small and create new tags only when there is stakeholder consensus
              for the requirement.{' '}
            </p>{' '}
          </Card>
        </Col>
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card bordered={false} className={styles.reinsuranceCardSeven}>
            <h2>Consistent use</h2>
            <p>
              Consistency is critical. Disregarding tagging standards will
              create complexity and consume time unnecessarily. A common example
              is missing or mislabeled cost allocation tags; if a large number
              of the deployed resources are affected, the cost analysis that
              leverages cost allocation tags could be grossly inaccurate.{' '}
            </p>{' '}
          </Card>
        </Col>
      </Row>

      <Row
        gutter={24}
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          marginBottom: '50px',
        }}
        className={styles.containerImgTypes2}
      >
        <h1 className={styles.typesTitles}>
          A few additional guidelines and limitations to pay attention to:
        </h1>

        <Col span={12} style={{ zIndex: '100' }}>
          <p className={styles.typesInfoTxt}>
            - AWS tags are case sensitive and must be spelled correctly <br />
            - Each AWS tag should have a key that is less than 127 Unicode
            characters in UTF-8
            <br />
            - Each value assigned should be less than 255 Unicode characters in
            UTF-8
            <br />
            - AWS Tags are merely simple strings that you can assign a value to.
            Assigning a value does not create any semantic meaning for AWS
            <br />
            - The value assigned to a tag can be an empty string but it cannot
            be NULL
            <br />
            - You are limited to a maximum of 50 tags for most services
            <br />
            - You cannot use “AWS” as a prefix for the tag since it is reserved
            for AWS
            <br />- Characters that are acceptable for tags are letters, spaces,
            and numbers representable in UTF-8, along with the following special
            characters: <b style={{ color: 'orange' }}>+ – = . _ : / @ </b>.
            <br />
            - There are some AWS resources that can only be tagged using an API
            or CLI
            <br />
          </p>
        </Col>
        <Col span={12} style={{ paddingRight: '0px' }}>
          <img
            src={bestPractices}
            style={{ transform: 'translate(0px, -26%)' }}
            className={styles.typesImg2}
            alt={'AWS best practices'}
          />
        </Col>
      </Row>
    </Content>
  );
}
